.hero-carousel-wrap {

}

.slide-item, .cover {
	overflow: hidden;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	&.overlay {
		position: relative;
		&:before {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			content: "";
			position: absolute;
			background: rgba($black, .2);
		}
	}

	& > .container {
		position: relative;
		z-index: 2;
	}
	&, & > .container > .row {
		height: 100vh;
		min-height: 760px;
	}

	.heading {
		font-size: 50px;
		font-weight: 700;
		color: $white;
	}
}


.feature-v1 {
	position: relative;
	z-index: 2;
	.pagination-item {
		padding: 30px;
		width: 33.333%;
		@include media-breakpoint-down(md) {
			width: 100%;
		}
		background: $light;
		&.active {
			background: $white;
			position: relative;
			&:before {
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				right: 0;
				height: 3px;
				margin-top: -3px;
				background: $primary;
			}
		}
		> .icon-wrap {
			flex: 0 0 90px;
			margin-right: 20px;
			img {
				width: 90px;
			}
		}
		> div {
			.subheading {
				font-size: 12px;
			}
			.heading {
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
}

.site-section {
	padding: 90px 0;
}

.service {
	background: $white;
	position: relative;
	top: 0;
	transition: .3s all ease;
	.service-inner {
		border: 1px solid rgba($black, .05);
		border-top: none;
		padding: 20px;
		text-align: center;
		h3 {
			font-size: 20px;
			color: $black;
			margin-bottom: 0;
		}
		p {
			font-size: 14px;
			color: #aaa;
		}
		*:last-child {
			margin-bottom: 0;
		}
	}
	&:hover {
		position: relative;
		top: -2px;
		box-shadow: 0 15px 30px 0 rgba($black, .05);
	}
}

.counter-wrap {
	text-align: center;
	.counter {
		font-size: 60px;
		color: $white;
		span {
			color: rgba($white, 1);
		}
	}
	span {
		color: rgba($white, .5);
	}
}


.custom-media {
	color: rgba($white, .6);
	font-size: 12px;
	.text {
		background: $primary;
		padding: 20px;
		h3 {
			color: $white;
			font-size: 20px;
		}
	}
	.img-bg {
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 250px;
		width: 100%;
	}
}


.feature {
	h3 {
		font-size: 16px;
		color: $white;
		font-weight: 700;
	}
	.img-wrap {
		display: inline-block;
		width: 80px;
		height: 80px;
		position: relative;
		border-radius: 50%;
		background: $white;
		img {
			position: absolute;
			width: 36px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

		}
	}
}

.testimonial {
	background: $white;
	padding: 20px;
	border: 1px solid $light;
	img {
		width: 80px;
		border-radius: 50%;
		margin-bottom: 30px;
		margin-top: -50px;
		box-shadow: 0 15px 30px 0 rgba($black, .05);

	}
	blockquote {
		font-size: 18px;
		font-family: $font-family-serif;
		font-style: italic;
		.quote {
			color: $black;
		}
		.author {
			color: #aaa;
			font-family: $font-family-sans-serif;
			font-size: 12px;
		}
	}
}

.blog-entry {
	text-align: center;
	h2 {
		font-size: 16px;
		font-weight: 700;
	}
	p {
		font-size: 14px;
	}
	.post-meta {
		background: $white;
		padding: 2px 4px;
		box-shadow: 0 15px 30px 0 rgba($black, .05);
		margin-bottom: 30px;
		text-align: center;
		font-size: 13px;
		[class*="icon-"] {
			color: $primary;	
		}
		> span {
			margin: 8px;

		}
	}
}



/*PRELOADING------------ */
#overlayer {
	width:100%;
	height:100%;  
	position:fixed;
	z-index:7100;
	background: $white;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.loader {
	z-index:7700;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}


/* Blog */

// sidebar
.sidebar-box {
	margin-bottom: 30px;
	padding: 25px;
	font-size: 15px;
	width: 100%;
	
	float: left;
	
	background: $white;
	*:last-child {
		margin-bottom: 0;
	}
	h3 {
		font-size: 18px;
		margin-bottom: 15px;
	}
}

.categories, .sidelink {
	li {
		position: relative;
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px dotted gray('300');
		list-style: none;
		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
			padding-bottom: 0;
		}
		a {
			display: block;
			span {
				position: absolute;
				right: 0;
				top: 0;
				color: #ccc;
			}
		}
		&.active {
			a {
				color: $black;
				font-style: italic;
			}
		}
	}
}


.comment-form-wrap {
	clear: both;
}

.comment-list {
	padding: 0;
	margin: 0;
	.children {
		padding: 50px 0 0 40px;
		margin: 0;
		float: left;
		width: 100%;
	}
	li {
		padding: 0;
		margin: 0 0 30px 0;
		float: left;
		width: 100%;
		clear: both;
		list-style: none;
		.vcard {
			width: 80px;
			float: left;
			img {
				width: 50px;
				border-radius: 50%;
			}
		}
		.comment-body {
			float: right;
			width: calc(100% - 80px);
			h3 {
				font-size: 20px;
			}
			.meta {
				text-transform: uppercase;
				font-size: 13px;
				letter-spacing: .1em;
				color: #ccc;
			}
			.reply {
				padding: 5px 10px;
				background: lighten($black, 90%);
				color: $black;
				text-transform: uppercase;
				font-size: 14px;
				&:hover {
					color: $black;
					background: lighten($black, 89%);
				}
			}
		}
	}
}

.search-form {
	background: lighten($black, 97%);
	padding: 10px;

	.form-group {
		position: relative;
		input {
			padding-right: 50px;
		}
	}
	.icon {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}
}

.post-meta {
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: .2em;
	a {
		color: $white;
		border-bottom: 1px solid rgba($white, .5);
	}
}

figure {
	figcaption {
		margin-top: .5rem;
		font-style: italic;
		font-size: .8rem;
	}
}

.card-title {
	font-size: 1.4rem;
	color: $black;
}



.team {
	img {
		border-radius: 50%;
		margin-bottom: 20px;
	}
	h3 {
		font-size: 18px;
		font-weight: 700;
		color: $black;
	}
	.position {
		margin-bottom: 30px;
		color: #aaa;
	}
}