/* Base */
html {
  overflow-x: hidden; }

body {
  line-height: 1.7;
  color: gray;
  font-weight: 400;
  font-size: 1rem; }

::-moz-selection {
  background: #000;
  color: #fff; }

::selection {
  background: #000;
  color: #fff; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a:hover {
    text-decoration: none; }

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  font-family: "Princesa", serif; }

.border-2 {
  border-width: 2px; }

.text-black {
  color: #000 !important; }

.bg-black {
  background: #000 !important; }

.color-black-opacity-5 {
  color: rgba(0, 0, 0, 0.5); }

.color-white-opacity-5 {
  color: rgba(255, 255, 255, 0.5); }

body:after {
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out; }

.offcanvas-menu {
  position: relative; }
  .offcanvas-menu:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.2); }

.btn {
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 10px 30px; }
  .btn:hover, .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .btn.btn-primary {
    background: #3c9399;
    border-color: #3c9399;
    color: #fff; }
    .btn.btn-primary:hover {
      background: #7b99bd;
      border-color: #7b99bd;
      color: #fff; }
    .btn.btn-primary.btn-black--hover:hover {
      background: #666666;
      color: #fff; }
  .btn.btn-white {
    border-color: #fff;
    color: #3c90f7;
    background: #fff; }
    .btn.btn-white:hover {
      background: transparent;
      border-color: #fff;
      color: #fff; }
  .btn.btn-outline-white {
    border-color: #fff;
    color: #fff; }
    .btn.btn-outline-white:hover {
      background: #fff;
      color: #3c90f7; }

.line-height-1 {
  line-height: 1 !important; }

.bg-black {
  background: #000; }

.form-control {
  height: 43px;
  border-radius: 4px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  .form-control:active, .form-control:focus {
    border-color: #3c90f7; }
  .form-control:hover, .form-control:active, .form-control:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  background: transparent;
  height: 80px;
}
#header.header-transparent {
  background: transparent;;
}
#header.header-scrolled {
  background: rgba(239, 243, 241, 0.9);
  height: 80px;
}
#header .logo {
  font-size: 34px;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;


}

#div .benefits{
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: inherit;
  position: relative;
  display: flex;
  justify-content: center;
  height: var(--height);
  width: 100%;
  --height: 433px;
}

#div.benefit{
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  --height: 433px;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: inherit;
  position: absolute;
  background-color: #313131;
  border-radius: 1.6rem;
  box-shadow: 0 25px 50px rgba(0,0,0,.8);
  flex: 0 1 auto;
  height: var(--height);
  overflow: hidden;
  text-align: left;
  max-width: 56.2rem;
  transform: translateX(calc(var(--translate-x)));
  transition: transform .8s cubic-bezier(.16,1,.3,1);
  padding: 3.6rem;
  width: 43.4vw;
  --translate-x: -58%;
  z-index: 1;
  }


#header .logo a {
color: #fff;
}

#header .logo img {
max-height: 40px;
padding-right: 30px;
border-right: 4px solid #2c4e3c;
}
@media (max-width: 992px) {
#header .logo {
  font-size: 28px;
}
}
#logo {
  float: left;
  font-size: 36px;
  line-height: 100%;
  margin-right: 40px
}

#header.full-header #logo {
  padding-right: 30px;
  margin-right: 30px;
  border-right: 1px solid #eee
}

#header.transparent-header.full-header #logo {
  border-right-color: rgba(0, 0, 0, .1)
}

#logo a {
  color: #000
}

#logo img {
  max-width: 100%
}

#logo a.retina-logo {
  display: none
}

#logo a,
#logo a.standard-logo,
#logo img {
  display: block
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(26, 26, 26, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a, .navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 16px;
  color: #333333;
}
.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: #2c4e3c;
}
.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 16px;
}
.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: #2c4e3c;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

.site-section {
  padding: 2.5em 0; }
  @media (min-width: 768px) {
    .site-section {
      padding: 8em 0; } }
  .site-section.site-section-sm {
    padding: 4em 0; }

.site-section-heading {
  padding-bottom: 20px;
  margin-bottom: 0px;
  position: relative;
  font-size: 2.5rem; }
  @media (min-width: 768px) {
    .site-section-heading {
      font-size: 3rem; } }

.site-footer {
  padding: 4em 0; }
  @media (min-width: 768px) {
    .site-footer {
      padding: 8em 0; } }
  .site-footer .border-top {
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important; }
  .site-footer .social a {
    text-decoration: none;
    cursor: pointer; }
  .site-footer p {
    color: rgba(0, 0, 0, 0.7); }
  .site-footer h2, .site-footer h3, .site-footer h4, .site-footer h5 {
    color: #000; }
  .site-footer a {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: underline; }
    .site-footer a:hover {
      color: black; }
  .site-footer ul li {
    margin-bottom: 10px; }
  .site-footer .footer-heading {
    font-size: 16px;
    color: #000; }

.bg-text-line {
  display: inline;
  background: #000;
  -webkit-box-shadow: 20px 0 0 #000, -20px 0 0 #000;
  box-shadow: 20px 0 0 #000, -20px 0 0 #000; }

.text-black-opacity-05 {
  color: rgba(0, 0, 0, 0.5); }

.text-black-opacity-05 {
  color: rgba(0, 0, 0, 0.5); }

.hover-bg-enlarge {
  overflow: hidden;
  position: relative; }
  @media (max-width: 991.98px) {
    .hover-bg-enlarge {
      height: auto !important; } }
  .hover-bg-enlarge > div {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .8s all ease-in-out;
    -o-transition: .8s all ease-in-out;
    transition: .8s all ease-in-out; }
  .hover-bg-enlarge:hover > div, .hover-bg-enlarge:focus > div, .hover-bg-enlarge:active > div {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }
  @media (max-width: 991.98px) {
    .hover-bg-enlarge .bg-image-md-height {
      height: 300px !important; } }

.bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  .bg-image.overlay {
    position: relative; }
    .bg-image.overlay:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.7); }
  .bg-image > .container {
    position: relative;
    z-index: 1; }

@media (max-width: 991.98px) {
  .img-md-fluid {
    max-width: 100%; } }

@media (max-width: 991.98px) {
  .display-1, .display-3 {
    font-size: 3rem; } }

.play-single-big {
  width: 90px;
  height: 90px;
  display: inline-block;
  border: 2px solid #fff;
  color: #fff !important;
  border-radius: 50%;
  position: relative;
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out; }
  .play-single-big > span {
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-40%, -50%);
    -ms-transform: translate(-40%, -50%);
    transform: translate(-40%, -50%); }
  .play-single-big:hover {
    width: 120px;
    height: 120px; }

.overlap-to-top {
  margin-top: -150px; }

.ul-check {
  margin-bottom: 50px; }
  .ul-check li {
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    line-height: 1.5; }
    .ul-check li:before {
      left: 0;
      font-size: 20px;
      top: -.3rem;
      font-family: "icomoon";
      content: "\e5ca";
      position: absolute; }
  .ul-check.white li:before {
    color: #fff; }
  .ul-check.success li:before {
    color: #71bc42; }
  .ul-check.primary li:before {
    color: #3c90f7; }
  .ul-check li.remove {
    text-decoration: line-through;
    color: #dee2e6; }
    .ul-check li.remove:before {
      color: #dee2e6; }

.select-wrap, .wrap-icon {
  position: relative; }
  .select-wrap .icon, .wrap-icon .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 22px; }
  .select-wrap select, .wrap-icon select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%; }

/* Navbar */
.site-logo {
  font-size: 20px;
  font-weight: bold; }

.site-navbar {
  margin-bottom: 0px;
  z-index: 1999;
  position: absolute;
  top: 0;
  width: 100%; }
  .site-navbar .site-logo {
    position: relative;
    left: 0; }
  .site-navbar .site-navigation .site-menu {
    margin-bottom: 0; }
    .site-navbar .site-navigation .site-menu .active {
      color: #3c90f7;
      display: inline-block;
      padding: 20px 20px; }
    .site-navbar .site-navigation .site-menu a {
      text-decoration: none !important;
      display: inline-block; }
    .site-navbar .site-navigation .site-menu > li {
      display: inline-block; }
      .site-navbar .site-navigation .site-menu > li > a {
        padding: 20px 20px;
        color: #000;
        display: inline-block;
        text-decoration: none !important; }
        .site-navbar .site-navigation .site-menu > li > a:hover {
          color: #3c90f7; }
      .site-navbar .site-navigation .site-menu > li.social > a {
        top: 15px;
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid #efefef;
        border-radius: 50%; }
        .site-navbar .site-navigation .site-menu > li.social > a span {
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
        .site-navbar .site-navigation .site-menu > li.social > a:hover {
          color: #3c90f7; }
    .site-navbar .site-navigation .site-menu .has-children {
      position: relative; }
      .site-navbar .site-navigation .site-menu .has-children > a {
        position: relative;
        padding-right: 20px; }
        .site-navbar .site-navigation .site-menu .has-children > a:before {
          position: absolute;
          content: "\e313";
          font-size: 16px;
          top: 50%;
          right: 0;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          font-family: 'icomoon'; }
      .site-navbar .site-navigation .site-menu .has-children .dropdown {
        visibility: hidden;
        opacity: 0;
        top: 100%;
        position: absolute;
        text-align: left;
        border-top: 2px solid #3c90f7;
        -webkit-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
        padding: 0px 0;
        margin-top: 20px;
        margin-left: 0px;
        background: #fff;
        -webkit-transition: 0.2s 0s;
        -o-transition: 0.2s 0s;
        transition: 0.2s 0s; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
          position: absolute; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
            bottom: 100%;
            left: 20%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top:before {
            border-color: rgba(136, 183, 213, 0);
            border-bottom-color: #fff;
            border-width: 10px;
            margin-left: -10px; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown a {
          text-transform: none;
          letter-spacing: normal;
          -webkit-transition: 0s all;
          -o-transition: 0s all;
          transition: 0s all;
          color: #000; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown .active {
          color: #3c90f7 !important; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown > li {
          list-style: none;
          padding: 0;
          margin: 0;
          min-width: 210px; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
            padding: 9px 20px;
            display: block; }
            .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a:hover {
              background: #eff1f3;
              color: #000; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > a:before {
            content: "\e315";
            right: 20px; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
            left: 100%;
            top: 0; }
      .site-navbar .site-navigation .site-menu .has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children:focus > a, .site-navbar .site-navigation .site-menu .has-children:active > a {
        color: #3c90f7; }
      .site-navbar .site-navigation .site-menu .has-children:hover, .site-navbar .site-navigation .site-menu .has-children:focus, .site-navbar .site-navigation .site-menu .has-children:active {
        cursor: pointer; }
        .site-navbar .site-navigation .site-menu .has-children:hover > .dropdown, .site-navbar .site-navigation .site-menu .has-children:focus > .dropdown, .site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
          -webkit-transition-delay: 0s;
          -o-transition-delay: 0s;
          transition-delay: 0s;
          margin-top: 0px;
          visibility: visible;
          opacity: 1; }

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out; }
  .offcanvas-menu .site-mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%); }
  .site-mobile-menu .site-mobile-menu-header {
    width: 100%;
    float: left;
    padding-left: 20px;
    padding-right: 20px; }
    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
      float: right;
      margin-top: 8px; }
      .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
        font-size: 30px;
        display: inline-block;
        padding-left: 10px;
        padding-right: 0px;
        line-height: 1;
        cursor: pointer;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease; }
    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
      float: left;
      margin-top: 10px;
      margin-left: 0px; }
      .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
        display: inline-block;
        text-transform: uppercase; }
        .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
          max-width: 70px; }
        .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
          text-decoration: none; }
  .site-mobile-menu .site-mobile-menu-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 0 20px 20px 20px;
    height: calc(100vh - 52px);
    padding-bottom: 150px; }
  .site-mobile-menu .site-nav-wrap {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative; }
    .site-mobile-menu .site-nav-wrap a {
      padding: 10px 20px;
      display: block;
      position: relative;
      color: #212529; }
      .site-mobile-menu .site-nav-wrap a:hover {
        color: #3c90f7; }
    .site-mobile-menu .site-nav-wrap li {
      position: relative;
      display: block; }
      .site-mobile-menu .site-nav-wrap li .active {
        color: #3c90f7; }
    .site-mobile-menu .site-nav-wrap .social {
      display: inline-block; }
      .site-mobile-menu .site-nav-wrap .social:hover {
        color: #3c90f7; }
    .site-mobile-menu .site-nav-wrap .arrow-collapse {
      position: absolute;
      right: 0px;
      top: 10px;
      z-index: 20;
      width: 36px;
      height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
        background: #f8f9fa; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse:before {
        font-size: 16px;
        z-index: 20;
        font-family: "icomoon";
        content: "\f078";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-180deg);
        -ms-transform: translate(-50%, -50%) rotate(-180deg);
        transform: translate(-50%, -50%) rotate(-180deg);
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    .site-mobile-menu .site-nav-wrap > li {
      display: block;
      position: relative;
      float: left;
      width: 100%; }
      .site-mobile-menu .site-nav-wrap > li.social {
        float: none !important;
        width: auto !important; }
      .site-mobile-menu .site-nav-wrap > li > a {
        padding-left: 20px;
        font-size: 20px; }
      .site-mobile-menu .site-nav-wrap > li > ul {
        padding: 0;
        margin: 0;
        list-style: none; }
        .site-mobile-menu .site-nav-wrap > li > ul > li {
          display: block; }
          .site-mobile-menu .site-nav-wrap > li > ul > li > a {
            padding-left: 40px;
            font-size: 16px; }
          .site-mobile-menu .site-nav-wrap > li > ul > li > ul {
            padding: 0;
            margin: 0; }
            .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
              display: block; }
              .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
                font-size: 16px;
                padding-left: 60px; }
    .site-mobile-menu .site-nav-wrap[data-class="social"] {
      float: left;
      width: 100%;
      margin-top: 30px;
      padding-bottom: 5em; }
      .site-mobile-menu .site-nav-wrap[data-class="social"] > li {
        width: auto; }
        .site-mobile-menu .site-nav-wrap[data-class="social"] > li:first-child a {
          padding-left: 15px !important; }

.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%; }
  .sticky-wrapper .site-navbar {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
  .sticky-wrapper .site-navbar .site-menu-toggle {
    color: #fff; }
  .sticky-wrapper .site-navbar .site-logo a {
    color: #fff; }
  .sticky-wrapper .site-navbar .site-menu > li > a {
    color: rgba(255, 255, 255, 0.7) !important; }
    .sticky-wrapper .site-navbar .site-menu > li > a:hover, .sticky-wrapper .site-navbar .site-menu > li > a.active {
      color: #fff !important; }
  .sticky-wrapper.is-sticky .site-navbar {
    background: #fff;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.1); }
    .sticky-wrapper.is-sticky .site-navbar .site-menu-toggle {
      color: #000; }
    .sticky-wrapper.is-sticky .site-navbar .site-logo a {
      color: #000; }
    .sticky-wrapper.is-sticky .site-navbar .site-menu > li > a {
      color: #000 !important; }
      .sticky-wrapper.is-sticky .site-navbar .site-menu > li > a:hover, .sticky-wrapper.is-sticky .site-navbar .site-menu > li > a.active {
        color: #3c90f7 !important; }

/* Blocks */
.slide-item, .cover {
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  .slide-item.overlay, .cover.overlay {
    position: relative; }
    .slide-item.overlay:before, .cover.overlay:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, 0.2); }
  .slide-item > .container, .cover > .container {
    position: relative;
    z-index: 2; }
  .slide-item, .slide-item > .container > .row, .cover, .cover > .container > .row {
    height: 100vh;
    min-height: 760px; }
  .slide-item .heading, .cover .heading {
    font-size: 50px;
    font-weight: 700;
    color: #fff; }

  

.feature-v1 {
  position: relative;
  z-index: 2; }
  .feature-v1 .pagination-item {
    padding: 30px;
    width: 33.333%;
    background: #f8f9fa; }
    @media (max-width: 991.98px) {
      .feature-v1 .pagination-item {
        width: 100%; } }
    .feature-v1 .pagination-item.active {
      background: #fff;
      position: relative; }
      .feature-v1 .pagination-item.active:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        height: 3px;
        margin-top: -3px;
        background: #3c90f7; }
    .feature-v1 .pagination-item > .icon-wrap {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 90px;
      flex: 0 0 90px;
      margin-right: 20px; }
      .feature-v1 .pagination-item > .icon-wrap img {
        width: 90px; }
    .feature-v1 .pagination-item > div .subheading {
      font-size: 16px; }
    .feature-v1 .pagination-item > div .heading {
      font-size: 16px;
      font-weight: bold; }

.site-section {
  padding: 90px 0; }

.service {
  background: #fff;
  position: relative;
  top: 0;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  .service .service-inner {
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-top: none;
    padding: 20px;
    text-align: center; }
    .service .service-inner h3 {
      font-size: 20px;
      color: #000;
      margin-bottom: 0; }
    .service .service-inner p {
      font-size: 20px;
      color: #aaa; }
    .service .service-inner *:last-child {
      margin-bottom: 0; }
  .service:hover {
    position: relative;
    top: -2px;
    -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05); }

.counter-wrap {
  text-align: center; }
  .counter-wrap .counter {
    font-size: 60px;
    color: #fff; }
    .counter-wrap .counter span {
      color: white; }
  .counter-wrap span {
    color: rgba(255, 255, 255, 0.5); }

.custom-media {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px; }
  .custom-media .text {
    background: #517aad;
    padding: 20px; }
    .custom-media .text h3 {
      color: #fff;
      font-size: 20px; }
  .custom-media .img-bg {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 250px;
    width: 100%; }

.feature h3 {
  font-size: 16px;
  color: #fff;
  font-weight: 700; }

.feature .img-wrap {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  background: #fff; }
  .feature .img-wrap img {
    position: absolute;
    width: 36px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.testimonial {
  background: #fff;
  padding: 20px;
  border: 1px solid #f8f9fa; }
  .testimonial img {
    width: 80px;
    border-radius: 50%;
    margin-bottom: 30px;
    margin-top: -50px;
    -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05); }
  .testimonial blockquote {
    font-size: 18px;
    font-family: "Playfair Display", serif;
    font-style: italic; }
    .testimonial blockquote .quote {
      color: #000; }
    .testimonial blockquote .author {
      color: #aaa;
      font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 16px; }

.blog-entry {
  text-align: center; }
  .blog-entry h2 {
    font-size: 16px;
    font-weight: 700; }
  .blog-entry p {
    font-size: 16px; }
  .blog-entry .post-meta {
    background: #fff;
    padding: 2px 4px;
    -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
    text-align: center;
    font-size: 16px; }
    .blog-entry .post-meta [class*="icon-"] {
      color: #3c90f7; }
    .blog-entry .post-meta > span {
      margin: 8px; }

/*PRELOADING------------ */
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7100;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.loader {
  z-index: 7700;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

/* Blog */
.sidebar-box {
  margin-bottom: 30px;
  padding: 25px;
  font-size: 16px;
  width: 100%;
  float: left;
  background: #fff; }
  .sidebar-box *:last-child {
    margin-bottom: 0; }
  .sidebar-box h3 {
    font-size: 18px;
    margin-bottom: 15px; }

.categories li, .sidelink li {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #dee2e6;
  list-style: none; }
  .categories li:last-child, .sidelink li:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0; }
  .categories li a, .sidelink li a {
    display: block; }
    .categories li a span, .sidelink li a span {
      position: absolute;
      right: 0;
      top: 0;
      color: #ccc; }
  .categories li.active a, .sidelink li.active a {
    color: #000;
    font-style: italic; }

.comment-form-wrap {
  clear: both; }

.comment-list {
  padding: 0;
  margin: 0; }
  .comment-list .children {
    padding: 50px 0 0 40px;
    margin: 0;
    float: left;
    width: 100%; }
  .comment-list li {
    padding: 0;
    margin: 0 0 30px 0;
    float: left;
    width: 100%;
    clear: both;
    list-style: none; }
    .comment-list li .vcard {
      width: 80px;
      float: left; }
      .comment-list li .vcard img {
        width: 50px;
        border-radius: 50%; }
    .comment-list li .comment-body {
      float: right;
      width: calc(100% - 80px); }
      .comment-list li .comment-body h3 {
        font-size: 20px; }
      .comment-list li .comment-body .meta {
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: .1em;
        color: #ccc; }
      .comment-list li .comment-body .reply {
        padding: 5px 10px;
        background: #e6e6e6;
        color: #000;
        text-transform: uppercase;
        font-size: 16px; }
        .comment-list li .comment-body .reply:hover {
          color: #000;
          background: #e3e3e3; }

.search-form {
  background: #f7f7f7;
  padding: 10px; }
  .search-form .form-group {
    position: relative; }
    .search-form .form-group input {
      padding-right: 50px; }
  .search-form .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }

.post-meta {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: .2em; }
  .post-meta a {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5); }

figure figcaption {
  margin-top: .5rem;
  font-style: italic;
  font-size: .8rem; }

.card-title {
  font-size: 1.4rem;
  color: #000; }

.team img {
  border-radius: 50%;
  margin-bottom: 20px; }

.team h3 {
  font-size: 18px;
  font-weight: 700;
  color: #000; }

.team .position {
  margin-bottom: 30px;
  color: #aaa; }


/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 60px;
}

.column-66 {
  float: left;
  width: 66.66666%;
  padding: 20px;
}

.column-33 {
  float: left;
  width: 33.33333%;
  padding: 20px;
}

div.callout {
	height: 60px;
	width: 200px;
	float: left;
}

div.callout {
	background-color: #444;
	background-image: -moz-linear-gradient(top, #444, #444);
	position: relative;
	color: #ccc;
	padding: 10px;
	border-radius: 3px;
	box-shadow: 0px 0px 20px #999;
	margin: 25px;
	min-height: 50px;
	border: 1px solid #333;
	text-shadow: 0 0 1px #000;
	
	/*box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset;*/
}

.callout::before {
	content: "";
	width: 0px;
	height: 0px;
	border: 0.8em solid transparent;
	position: absolute;
}

.callout.top::before {
	left: 45%;
	bottom: -20px;
	border-top: 10px solid #444;
}

.callout.bottom::before {
	left: 45%;
	top: -20px;
	border-bottom: 10px solid #444;
}

.callout.left::before {
	right: -20px;
	top: 40%;
	border-left: 10px solid #444;
}

.callout.right::before {
	left: -20px;
	top: 40%;
	border-right: 10px solid #444;
}

.callout.top-left::before {
	left: 7px;
	bottom: -20px;
	border-top: 10px solid #444;
}

.callout.top-right::before {
	right: 7px;
	bottom: -20px;
	border-top: 10px solid #444;
}